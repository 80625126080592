import React from "react"
import { Link } from "gatsby"

import styles from "./episode.module.css"

const Episode = ({ to, title, blurb, coverImage }) => (
  <Link to={to} className={styles.episodeLink}>
    <div className={styles.episode}>
      <div className={styles.title}>{title}</div>
      <div className={styles.blurb}>{blurb}</div>
    </div>
  </Link>
)

export default Episode
