import React from "react"

import Layout from "../components/layout"
import Episode from "../components/episode"
import styles from "./index.module.css"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1>
      Welcome to <span className={styles.textBlue}>CQ</span>!
    </h1>
    <p className={styles.textItalic}>
      Explore by clicking on each of the episodes below.
    </p>
    <div className={styles.episodeWrapper}>
      <Episode
        to="/episode1/"
        title="Episode 1"
        blurb="This month's challenge focuses on retail, cybersecurity, the Cloud, and more. Take the Smartfirm Challenge to find out how well you've kept up with what's happening."
      />

      <Episode
        to="/episode2/"
        title="Episode 2"
        blurb="This week's challenge includes developments in AI and robotics, the importance of software change management, and developments in privacy legislation."
      />
    </div>
  </Layout>
)

export default IndexPage
